<template>
  <div class="page-content">
    <main id="page-main" class="page__main">
      <div>
        <Header />
        <Banner />
        <slot />
        <Footer />
        <client-only>
          <ModalLogin />
          <ModalResetPassword />
          <ModalChangeDisplayName v-if="isLogged" />
          <ModalBlockGame v-if="isLogged" />
        </client-only>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import Header from '~/components/desktop/header/index.vue'
import Footer from '~/components/desktop/footer/index.vue'
import Banner from '~/components/desktop/layouts/banner/index.vue'
import ModalLogin from '~/components/common/modals/login/index.vue'
import ModalResetPassword from '~/components/common/modals/reset-password/index.vue'
import { useAppStore } from '~/store/app'
import { useInitData } from '~~/composables/user/useInitData'
import { useRefresh } from '~/composables/user/useRefresh'
const ModalChangeDisplayName = defineAsyncComponent(
  () => import('~/components/desktop/modals/change-display-name/index.vue')
)
const ModalBlockGame = defineAsyncComponent(() => import('~/components/desktop/modals/block-game/index.vue'))
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const route = useRoute()
const { isLogged } = storeToRefs(store)
const { refreshTokenInterval, refreshToken } = useRefresh()

const { fetchInitData, fetchPlan, fetchUserRank } = useInitData()
onMounted(() => {
  refreshToken(true)
  if (isLogged.value) {
    userInitData()
  }

  setTimeout(() => {
    const modalIds = ['modalBlockGame', 'modalCancelPromotion', 'modalDisplayName']

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        const modalElement = entry.target as HTMLElement
        const modalId = modalElement.id
        if (entry.isIntersecting) {
          const bodyElement = document.querySelector('body')
          if (bodyElement) {
            disablePageScroll(bodyElement)
          }
        } else {
          enablePageScroll(document.querySelector('body'))
        }
      })
    }
    const observer = new IntersectionObserver(handleIntersection, { threshold: 0 })

    modalIds.forEach((modalId) => {
      const modalElement = document.getElementById(modalId)
      if (modalElement) {
        observer.observe(modalElement)
      }
    })
  }, 1000)
})
watch(isLogged, () => {
  refreshToken(true)
  if (isLogged.value) {
    userInitData()
    removeParams()
  }
})

const userInitData = () => {
  refreshTokenInterval()
  fetchInitData()
  fetchPlan()
  fetchUserRank()
}

const removeParams = () => {
  const query = { ...route.query }
  delete query.popup
  navigateTo({
    query: { ...query }
  })
}
</script>
<style lang="scss" scoped src="assets/scss/layout/default.scss"></style>
