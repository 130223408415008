<template>
  <div class="user-login">
    <div class="user-login__balance" :class="{ disabled: isShowModalDeposit }" @click="onNavDeposit">
      <BaseImg src="/assets/images/components/desktop/pages/home/header/icon-plus.svg" />
      <span class="user-login__balance--amount">{{ $formatNumberWithCommas(totalBalance, ',') }}</span>
      <span class="user-login__balance--unit">K</span>
    </div>
    <div v-click-outside="() => (showDropdown = false)" class="user-login__info" @click="handleClickInfo">
      <div class="user-login__info--top">
        <div class="user-login__info--avatar">
          <BaseImg :src="currentAvatar" class="img-defaul img-fluid" />
          <BaseImg
            src="/assets/images/components/desktop/pages/account/avatar/avatar-pc-yellow-hover.png"
            class="img-hover"
          />
        </div>
        <p class="user-login__info--fullname">{{ fullName }}</p>
      </div>
      <div v-if="showDropdown" class="menu">
        <div class="menu__group-btn">
          <BaseButton class="button-deposit base-button--bg-golden" @click="onNavDeposit()">
            <p>NẠP</p>
          </BaseButton>
          <BaseButton class="button-withdraw base-button--bg-red" @click="onNavWithdraw()">
            <p>RÚT</p>
          </BaseButton>
        </div>
        <div class="menu__list">
          <div class="item" @click="openLink(ACCOUNT_URLS.PROFILE)">
            <div class="item__left">
              <BaseImg src="/assets/images/components/desktop/pages/home/header/menu/account.svg" />
              <span>Quản lý tài khoản</span>
            </div>
            <div class="item__right">
              <BaseImg src="/assets/images/components/desktop/pages/home/header/arrow-down.svg" />
            </div>
          </div>
          <div class="item" @click="openLink(ACCOUNT_URLS.PROFIT_TODAY)">
            <div class="item__left">
              <BaseImg src="/assets/images/components/desktop/pages/home/header/icon-bonus.svg" />
              <span>Thưởng của bạn</span>
            </div>
            <div class="item__right">
              <BaseImg src="/assets/images/components/desktop/pages/home/header/arrow-down.svg" />
            </div>
          </div>
          <div class="item" @click="openLink(ACCOUNT_URLS.TRANSACTION_HISTORY)">
            <div class="item__left">
              <BaseImg src="/assets/images/components/desktop/pages/home/header/menu/transaction-history.svg" />
              <span>Lịch sử giao dịch</span>
            </div>
            <div class="item__right">
              <BaseImg src="/assets/images/components/desktop/pages/home/header/arrow-down.svg" />
            </div>
          </div>
          <div class="item" @click="openLink(ACCOUNT_URLS.BET_HISTORY)">
            <div class="item__left">
              <BaseImg src="/assets/images/components/desktop/pages/home/header/menu/betting-history.svg" />
              <span>Lịch sử cá cược</span>
            </div>
            <div class="item__right">
              <BaseImg src="/assets/images/components/desktop/pages/home/header/arrow-down.svg" />
            </div>
          </div>
          <div class="item" @click="logout()">
            <div class="item__left">
              <BaseImg src="/assets/images/components/desktop/pages/home/header/menu/logout.svg" />
              <span>Logout</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import BaseButton from '~/components/common/base-button.vue'
import { useLogout } from '~/composables/user/useLogout'
import { ACCOUNT_URLS } from '~/config/page-url'

const { $pinia, $formatNumberWithCommas } = useNuxtApp()
const store = useAppStore($pinia)
const { logout } = useLogout()
const { currentUser } = storeToRefs(store)
const showDropdown = ref(false)
const isShowModalDeposit = ref(false)

const currentAvatar = computed(() => {
  return !showDropdown.value
    ? '/assets/images/components/desktop/pages/account/avatar/avatar-pc-default.png'
    : '/assets/images/components/desktop/pages/account/avatar/avatar-pc-yellow.png'
})

const fullName = computed(() => {
  return currentUser?.value?.fullname || currentUser?.value?.username || ''
})

const totalBalance = computed(() => {
  return currentUser?.value?.balance ?? 0
})

const handleClickInfo = debounce(() => {
  showDropdown.value = !showDropdown.value
}, 100)

const onNavDeposit = () => {
  if (useRoute().path !== ACCOUNT_URLS.DEPOSIT_DA) {
    navigateTo(ACCOUNT_URLS.DEPOSIT_DA)
  }
}
const onNavWithdraw = () => {
  if (useRoute().path === ACCOUNT_URLS.WITHDRAW_BANK) { return }
  navigateTo(ACCOUNT_URLS.WITHDRAW_BANK)
}

const openLink = (url: string) => {
  if (useRoute().path !== url) {
    navigateTo(url)
  }
}
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/header/user-logged.scss" />
