<template>
  <footer class="wrapper-footer">
    <div class="wrapper-footer__content container">
      <div class="wrapper-footer__top">
        <div class="wrapper-footer__top--left">
          <nuxt-link to="/" class="logo">
            <BaseImg
              src="/assets/images/logo.svg"
              alt="logo"
              @click="goHome"
            />
          </nuxt-link>
          <p class="desc">
            {{ FOOTER_CONTENT.DESCRIPTION }}
          </p>
        </div>
      </div>
      <div class="socials__wrapper border-bottom">
        <a @click.prevent="$openLiveChat()">
          <div class="item">
            <BaseImg src="/assets/images/components/desktop/footer/live-chat.svg" alt="icon" class="icon-contact" />
            <div class="content">
              <p class="title">Live chat 24/7</p>
              <p class="text">Chat với chúng tôi</p>
            </div>
          </div>
        </a>
        <a :href="`mailto:${$config.EMAIL_SUPPORT}`">
          <div class="item">
            <BaseImg src="/assets/images/components/desktop/footer/icon-email.svg" alt="icon" class="icon-contact" />
            <div class="content">
              <p class="title">Mail</p>
              <p class="text">@{{ $config.EMAIL_SUPPORT }}</p>
            </div>
          </div>
        </a>
        <a :href="'https://page.line.me/soibet'" target="_blank">
          <div class="item">
            <BaseImg src="/assets/images/components/desktop/footer/telegram.svg" alt="icon" class="icon-contact" />
            <div class="content">
              <p class="title">Telegram</p>
              <p class="text">{{ $config.TELEGRAM_CHANNEL }}</p>
            </div>
          </div>
        </a>
      </div>
      <div class="wrapper-footer__bottom">
        <p class="text">Copyright © {{ getFullYear }} Powered By Soibet All Rights Reserved.</p>
        <div class="menu">
          <template v-for="(item, index) in menuBottom" :key="index">
            <nuxt-link :to="item.to" class="menu__link">{{ item.title }}</nuxt-link>
            <div v-if="index !== menuBottom.length - 1" class="menu__line" />
          </template>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import BaseImg from '~/components/common/base-img.vue'
import { menuBottom } from '~/resources/footer'
import { PAGE_URLS } from '~/config/page-url'
import { FOOTER_CONTENT } from '@/constants/footer'

const { $config, $openLiveChat } = useNuxtApp()
const router = useRouter()

const getFullYear = computed(() => {
  return new Date().getFullYear()
})
const goHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/footer/index.scss" />
