import { SPORTS_URLS, PAGE_URLS, ACCOUNT_URLS } from '~/config/page-url'
import { PAGE_PARAMS } from '~/constants/common'
import { LOBBY_GAME_URLS } from '~/config/page-url'
const PATH_IMAGE = '/assets/images/components/desktop/footer/'
export const FOOTER_NAVIGATION = [
  {
    title: 'Thể thao',
    subCategories: [
      { title: 'Lịch thi đấu', link: SPORTS_URLS.SCHEDULES },
      { title: 'Cược thể thao', link: SPORTS_URLS.INDEX }
    ]
  },
  {
    title: 'Trò chơi',
    subCategories: [
      { title: 'Bắn cá', link: LOBBY_GAME_URLS.BAN_CA },
      { title: 'Slots', link: LOBBY_GAME_URLS.SLOTS_GAME },
      { title: 'Nổ hũ', link: LOBBY_GAME_URLS.NO_HU },
      { title: 'Games bài', link: LOBBY_GAME_URLS.GAME_BAI },
      { title: 'Lô đề', link: LOBBY_GAME_URLS.LO_DE },
      { title: 'Table games', link: LOBBY_GAME_URLS.TABLE_GAME },
      { title: 'Quay số', link: LOBBY_GAME_URLS.QUAY_SO },
      { title: 'Games nhanh', link: LOBBY_GAME_URLS.QUICK_GAMES }
    ]
  },
  {
    title: 'Về chúng tôi',
    subCategories: [
      { title: `Giới thiệu ${process.env.BRANCH_NAME}`, link: `${PAGE_URLS.INTRODUCTION}` },
      { title: 'Điều kiện - Điều khoản', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.POLICY}` },
      { title: 'Bảo mật', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.SECURITY}` },
      { title: 'Chơi games có trách nhiệm', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.RESPONSIBILITY}` },
      { title: 'Quy định chung', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.RULE}` }
    ]
  },
  {
    title: 'Hỗ trợ 24/7',
    subCategories: [
      { title: 'Trực tuyến 24/7', type: 'BLANK', link: `https://secure.livechatinc.com/licence/${process.env.LIVE_CHAT}/v2/open_chat.cgi` },
      { title: 'Cộng đồng DA88', type: 'BLANK', link: process.env.TELEGRAM_CSKH },
      { title: 'Hướng dẫn Nạp/Rút', link: `${PAGE_URLS.INTRODUCTION}?page=${PAGE_PARAMS.DEPOSIT}` }
    ]
  },
  {
    title: 'Thông tin',
    subCategories: [
      { title: 'Khuyến mãi /Sự kiện', link: PAGE_URLS.PROMOTION }
    ]
  }
]

export const DEPOSIT_LIST = [
  {
    title: 'CodePay',
    image: `${PATH_IMAGE}nicepay.svg`,
    link: ACCOUNT_URLS.DEPOSIT_DA
  },
  {
    title: 'Ví điện tử',
    image: `${PATH_IMAGE}digital-wallet.svg`,
    link: ACCOUNT_URLS.DEPOSIT_MOMO
  },
  {
    title: 'Thẻ cào',
    image: `${PATH_IMAGE}card.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CARD
  },
  {
    title: 'Tiền ảo',
    image: `${PATH_IMAGE}crypto.svg`,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO
  }
]

export const FOOTER_CONTENT = {
  SLOGAN: `${process.env.BRANCH_NAME} - Nhà cái cá cược uy tín nhất Châu Á, nạp rút siêu tốc.`,
  DESCRIPTION: `Nhà cái ${process.env.BRANCH_NAME} là trang cược bóng đá nhanh nhất trên thị trường hiện nay, hoàn trả mỗi ngày cao nhất 1.8%. ${process.env.BRANCH_NAME} cung cấp hai loại bảng kèo K-Sports; C-Sports và tỷ lệ cược liên tục cập nhật từng giây. Lịch thi đấu mỗi ngày, phân tích trận đấu, soi kèo banh Ngoại Hạnh Anh, Cúp C1, Serie A, Laliga, Bundesliga, Ligue 1,... và tất cả các giải lớn nhỏ khác. Trải nghiệm mượt mà, mọi lúc mọi nơi.`,
  SLOGAN_DESCRIPTION: `${process.env.BRANCH_NAME} - Nhà cái đẳng cấp Châu Âu. ${process.env.BRANCH_NAME} đem đến cho khách hàng dịch vụ cá cược trực tuyến hàng đầu với hàng trăm sản phẩm cược hấp dẫn như: Thể Thao, Thể Thao Ảo, Casino, Number Games, Keno, Sòng Bài Số...`,
  VIEW_MORE: 'xem thêm',
  COPY_RIGHT: `Copyright © 2024 Powered By ${process.env.BRANCH_NAME} All Rights Reserved.`
}
