<template>
  <div v-if="imageUrl" class="banner">
    <BaseImg :src="imageUrl" alt="hero-banner" />
  </div>
</template>

<script setup lang="ts">
import { PAGE_URLS } from '~~/config/page-url'

const route = useRoute()

const imageUrl = computed(() => {
  switch (true) {
    case route.fullPath.includes(PAGE_URLS.CASINO):
      return '/assets/images/components/desktop/pages/livecasino/banner.webp'
    case route.fullPath.includes(PAGE_URLS.GAME):
      return '/assets/images/components/desktop/pages/lobby-game/banner.webp'
    default:
      return ''
  }
})
</script>

<style scoped src="assets/scss/components/desktop/layouts/banner/index.scss" />
